import React from "react"
import BlogLayout from "../../components/Blog/BlogLayout"
import BlogEight from "../../components/Blog/BlogsList/BlogEight"
const BlogContent = () => {
  return (
    <BlogLayout>
      <BlogEight />
    </BlogLayout>
  )
}

export default BlogContent
